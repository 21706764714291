<!--全景数据转换-->
<template>
  <div>
  </div>
</template>
<script>
  import { uuid } from 'vue-uuid'
  export default {
    components: {
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        sending: false,
        updateing: false,
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {}
    },
    validations() {
      var v = {
        form: {
          items: {
            required: this.$required,
            maxLength: this.$maxLength(this.$inRole('管理员') ? 100 : 50),
            minLength: this.$minLength(1),
            $each: {
              hotspots: {
                maxLength: this.$maxLength(this.$inRole('管理员') ? 50 : 20),
                $each: {
                  url: {
                    isUrl: (value, p) => {
                      if (p.linkType == 'Link') {
                        if (value) {
                          return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}(\.[a-zA-Z0-9()]{1,6})\b(:[0-9]{1,5})?([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(value)
                        } else {
                          return false
                        }
                      }
                      return true
                    }
                  }
                }
              },
              title: {
                maxLength: this.$maxLength(50)
              },
              url: {
                isUrl: (value, p) => {
                  if (p.type == 'Link') {
                    if (value) {
                      return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}(\.[a-zA-Z0-9()]{1,6})\b(:[0-9]{1,5})?([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(value)
                    } else {
                      return false
                    }
                  }
                  return true
                },
                maxLength: this.$maxLength(256)
              }
            }
          },
          links: {
            maxLength: this.$maxLength(6),
            $each: {
              title: {
                required: this.$required,
                minLength: this.$minLength(2),
                maxLength: this.$maxLength(4)
              },
              link: {
                //required: this.$required,
                minLength: this.$minLength(2),
                maxLength: this.$maxLength(250),
                isLink: (value, p) => {
                  if (p.type == 'Url') {
                    if (value) {
                      return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(value)
                    } else {
                      return false
                    }
                  }
                  if (p.type == 'Email') {
                    if (value) {
                      return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
                    } else {
                      return false
                    }
                  }
                  if (p.type == 'Tel') {
                    if (value) {
                      return /^((0\d{2,3}-\d{7,8})|(1[35847]\d{9}))$/.test(value)
                    } else {
                      return false
                    }
                  }
                  return true
                }
              },
            }
          },
        }
      }
      console.log('rv', this.config.validations)
      var v2 = this.validationsTransform(this.config.validations || {})
      console.log('tv', v2)
      var rv = this.objAdd(v2, v)
      console.log('rv', rv)
      return rv
    },
    computed: {
      urls() {
        return this.publicData.urls
      },
      params() {
        return this.publicData.params
      },
      editHotspotGuid() {
        return this.publicData.editHotspotGuid
      },
      hotspots() {
        if (this.publicData.pano) {
          return this.publicData.pano.hotspots || []
        }
        return []
      },
      groupGuid() {
        return this.publicData.groupGuid
      },
      groupItems() {
        return this.publicData.groupItems || []
      },
      editItemGuid() {
        return this.publicData.editItemGuid
      },
      editItemIndex() {
        for (var i in this.form.items) {
          if (this.form.items[i].guid == this.editItemGuid) {
            return i
          }
        }
        return null
      },
      form() {
        return this.publicData.form || {}
      },
      rooms() {
        return this.publicData.rooms || {}
      },
      pano() {
        return this.publicData.pano || {}
      },
    },
    watch: {
      hotspots(val, oldval) {
        this.getFunc('changePublicData')({
          blockCount: this.blockCount({ hotspots: val }),
          hotspotCount: this.hotspotCount({ hotspots: val }),
        })
      },
      editItemGuid(val, oldval) {
        console.log('editItemGuid Change', val, oldval)
        this.updateTour(null, val, null)
      },
      groupGuid(val, oldval) {
        console.log('groupGuid Change')
        this.updateTour(null, null, val)
      },
      form(val, oldval) {
        console.log('form Change', val == oldval)
        if (val != oldval) {
          this.updateTour(val, null, null)
        }
      }
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        funcs: {
          formChage: this.formChage,
          setPanoView: this.setPanoView,
          setPanoCover: this.setPanoCover,
          setTourCover: this.setTourCover,
          setLinkView: this.setLinkView,
          updatePano: this.updatePano,
          updateTour: this.updateTour,
          deleteItem: this.deleteItem,
          //deleteGroup: this.deleteGroup,
          addGroup: this.addGroup,
          getRoom: this.getRoom,
          addItem: this.addItem,
          addItems: this.addItems,
          getGroupItems: this.getGroupItems,
          hotspotMove: this.hotspotMove,
          changePostprocessing: this.changePostprocessing,
          save: this.save
        },
        events: {
          titleInvalid: {
            target: 'baseSettingModel',
            name: 'show'
          },
          hotspotsInvalid: {
            name: 'renewSence',
            arg: 'hotspot'
          },
          addItem: {
            target: 'SenceSettingModel',
            name: 'addItemStart',
            arg: { index: 0 }
          },
          baseSetting: {
            target: 'baseSettingModel',
            name: 'show',
            arg: { index: 0 }
          }
        }
      })
      //this.updateGroup()
      //if (!(this.form.items && this.form.items.length > 0)) {
      //  setTimeout(() => {
      //    this.$emit('addItem')
      //  }, 1000)
      //}
      if (!this.form.title) {
        setTimeout(() => {
          this.$emit('baseSetting')
        }, 300)
      }
      this.updateTour()
      var g = this.getGroup(this.groupGuid)
      if (g && g.type == 'Blog') {
        this.getFunc('renewSence')('blog')
      }
      if (g && g.type == 'Gallery') {
        this.getFunc('renewSence')('gallery')
      }
    },
    created() {
    },
    destroyed() {
    },
    methods: {
      objAdd(obj1, obj2) {
        if (typeof obj1 == "object" && typeof obj2 == "object") {
          var r = {}
          for (var i in obj1) {
            r[i] = this.objAdd(obj1[i], obj2[i])
          }
          for (var i in obj2) {
            if (!r[i]) {
              r[i] = obj2[i]
            }
          }
        } else {
          return obj1
        }
        return r
      },
      validationsTransform(v) {
        var r = {}
        for (var i in v) {
          if (typeof v[i] == 'string') {
            if (v[i] == 'required') {
              r[i] = this.$required
            } else if (v[i].indexOf('maxLength(') == 0) {
              var int = Number(v[i].slice('maxLength('.length, v[i].length - 1))
              r[i] = this.$maxLength(int)
            } else if (v[i].indexOf('minLength(') == 0) {
              var int = Number(v[i].slice('minLength('.length, v[i].length - 1))
              r[i] = this.$minLength(int)
            } else if (v[i].indexOf('isMobile') == 0) {
              r[i] = this.$isMobile
            } else if (v[i].indexOf('isTel') == 0) {
              r[i] = this.$isTel
            } else if (v[i].indexOf('isUrl') == 0) {
              r[i] = this.$isUrl
            } else if (v[i].indexOf('notZero') == 0) {
              r[i] = this.$notZero
            }
          } else {
            r[i] = this.validationsTransform(v[i])
          }
        }
        return r
      },
      setPanoView(view) {
        for (var i in view) {
          this.form.items[this.editItemIndex][i] = view[i]
        }
      },
      setPanoCover(pano) {
        this.form.items[this.editItemIndex].linkItem = pano
      },
      setTourCover(tour) {
        this.form.cover = tour.cover
      },
      setLinkView(linkViewData) {
        console.log('setLinkView', linkViewData, this.editItemIndex)
        var item = null
        for (var i in this.form.items[this.editItemIndex].hotspots) {
          if (this.form.items[this.editItemIndex].hotspots[i].guid == this.editHotspotGuid) {
            item = this.form.items[this.editItemIndex].hotspots[i]
          }
        }
        for (var i in linkViewData) {
          item[i] = linkViewData[i]
        }
      },
      newGroup(groupName) {
        return {
          guid: uuid.v1(),
          title: groupName,
          description: '',
          audioAutoPlay: true,
          audioLoop: false,
          file: null,
          audio: null,
          type: 'Pano',
        }
      },
      //deleteGroup({ guid, deleteGroupItem }) {
      //  if (!guid) {
      //    return
      //  }
      //  var index = this.getGroupIndex(guid)
      //  for (var i = this.form.items.length - 1; i >= 0; i--) {
      //    if (this.form.items[i].groupGUID == guid) {
      //      if (deleteGroupItem) {
      //        this.deleteItem(this.form.items[i].guid)
      //      } else {
      //        this.form.items[i].groupName = null
      //        this.form.items[i].groupGUID = null
      //      }
      //    }
      //  }
      //  this.form.groups.splice(index, 1)
      //  if (guid == this.groupGuid) {
      //    var g = null
      //    if (this.form.groups[0]) {
      //      g = this.form.groups[0].guid
      //    }
      //    this.getFunc('changePublicData')({
      //      groupGuid: g,
      //    })
      //  }
      ////  this.updateGroup()
      //},
      addGroup({ index, group }) {

        if (!index) {
          index = this.form.groups.length
        }
        var ng = this.newGroup()
        for (var i in group) {
          ng[i] = group[i]
        }
        this.form.groups.splice(index, 0, ng)
      },
      newItem() {
        return {
          guid: uuid.v1(),
          description: '',
          file: null,
          fov: 120,
          groupName: null,
          groupGUID: null,
          hLookAt: 0,
          hotspots: [],
          linkItem: null,
          audio: null,
          audioAutoPlay: true,
          audioLoop: false,
          title: '',
          type: 'Pano',
          url: '',
          vLookAt: 0,
          vLookAtMax: 90,
          vLookAtMin: -90,
          weather: '无',
        }
      },
      deleteItem(guid) {
        console.log('deleteItem', guid)
        var index = this.getItemIndex(guid)
        if (!index) {
          return
        }
        this.form.items.splice(index, 1)
        if (guid == this.editItemGuid) {
          var g = 0
          if (this.groupItems[0]) {
            g = this.groupItems[0].guid
          }
          this.getFunc('changePublicData')({
            editItemGuid: g,
          })
        }
      },
      addItem({ index, groupGuid, item }) {
        if (!index) {
          index = this.form.items.length
        }
        var ni = this.newItem()
        ni.roomId = groupGuid
        ni.room = this.getRoom(groupGuid)
        //ni.groupName = this.getGroup(groupGuid).title
        for (var i in item) {
          ni[i] = item[i]
        }
        this.form.items.splice(index, 0, ni)
        this.updateTour()
      },
      addItems({ index, groupGuid, items }) {
        for (var i in items) {
          var item = items[i]
          this.addItem({ index: index + parseInt(i), groupGuid, item })
        }
      },
      getGroup(groupGuid) {
        for (var i in this.form.groups) {
          if (this.form.groups[i].guid == groupGuid) {
            return this.form.groups[i]
          }
        }
      },
      getGroupIndex(groupGuid) {
        for (var i in this.form.groups) {
          if (this.form.groups[i].guid == groupGuid) {
            return i
          }
        }
      },
      getGroupItems(roomId, tour) {
        var gg = roomId
        var g = []
        var t = tour || this.form
        for (var i in t.items) {
          var item = t.items[i]
          if (item.roomId == gg) {
            g.push(item)
          }
        }
        return g
      },
      isItemInGroup(guid, groupItems) {
        var gis = groupItems || this.groupItems
        for (var i in gis) {
          if (gis[i].guid == guid) {
            return true
          }
        }
        return false
      },
      getItemIndex(guid) {
        for (var i in this.form.items) {
          if (this.form.items[i].guid == guid) {
            return i
          }
        }
        return null
      },
      getRoom(id) {
        if (!id) {
          return null
        }
        if (this.rooms[id]) {
          return this.rooms[id]
        } else {
          var url = window.global.ENABLE_ELASTICSEARCH ? `/Api/ES/InnAdmin/InnRooms/${this.$route.params.innId}/${id}` : `/Api/InnAdmin/Rooms/${this.$route.params.innId}/${id}`;
          this.$axios.get(url)
            .then(res => {
              this.setRoom(res.data.id, res.data)
              this.publicData.form.items.splice(0, 0)
              this.$emit('roomsChange', this.rooms)
            })
          return { title: '加载中...', id }
        }
      },
      setRoom(id, data) {
        if (this.publicData.rooms) {
          this.publicData.rooms[id] = data
        } else {
          var rooms = {}
          rooms[id] = data
          this.getFunc('changePublicData')({
            rooms
          })
        }
      },
      hotspotMove({ item, ath, atv }) {
        item.ath = ath
        item.atv = atv
      },
      //updateGroup() {
      //  var groups = this.form.groups || []
      //  var g
      //  for (var i in this.form.items) {
      //    var item = this.form.items[i]
      //    if (item.groupGUID) {
      //      item.groupName = this.getGroup(item.groupGUID).title
      //    } else {
      //      //if (item.groupName) {
      //      if (!(g = this.isInGroup(item.groupName, groups))) {
      //        g = this.newGroup(item.groupName)
      //        groups.push(g)
      //      }
      //      item.groupGUID = g.guid
      //      //}
      //    }
      //  }
      //  this.form.groups = groups
      //  return groups
      //},
      isInGroup(groupName, groups) {
        for (var i in groups) {
          if (groups[i].title == groupName) {
            return groups[i]
          }
        }
        return false
      },

      formChage(type) {
        console.log('formChage', type)
        this.updateTour()
        switch (type) {
          case 'weather':
            this.getFunc({ target: 'FullPano', name: 'setWeather' })()
            break
          case 'postprocessing':
            this.getFunc({ target: 'FullPano', name: 'setPostprocessing' })()
            break
          case 'hotspot':
            this.getFunc({ target: 'FullPano', name: 'setHotspots' })()
            break
          default:
        }
      },
      updatePano(pano) {
        var p = pano
        if (!p) {
          var eig = this.editItemGuid || 0
          var eii = this.getItemIndex(eig) || 0
          p = this.itemToPano(this.form, eii)
        }
        console.log('updatePano', p.guid)
        this.getFunc('changePublicData')({ pano: p })
      },
      updateTour(tour, editItemGuid, groupGuid) {
        console.log('updateTour', tour, editItemGuid, groupGuid)
        var t = tour || this.form
        var eig = editItemGuid || this.editItemGuid || null
        var gg = groupGuid || this.groupGuid
        var eii = 0
        var gis = []
        var p = {}
        if (editItemGuid) {
          eii = this.getItemIndex(eig)
          gg = t.items[eii].groupGUID
        }
        if (groupGuid) {
          gis = this.getGroupItems(gg, t)
          if (!this.isItemInGroup(eig, gis) && gis[0]) {
            eig = gis[0].guid
          } else if (this.isItemInGroup(eig, gis)) {
          } else {
            eig = t.items[0].guid
          }
        }
        if (!gg) {
          var publicGroup = this.getGroupItems(null, t)
          if (publicGroup.length == 0) {
            if (t.items[0]) {
              gg = t.items[0].roomId
            }
          }
        }
        if (this.hasGroup(t.groups)) {
          gis = this.getGroupItems(gg, t)
        } else {
          gis = t.items
        }
        if (!eig) {
          if (gis[0]) {
            eig = gis[0].guid
          }
        }
        eii = this.getItemIndex(eig)
        var g = this.getGroup(gg)
        console.log('eii', eii, 'eig', eig, 'gg', gg, 'g', g)
        if (eii !== null && (!g || g.type == 'Pano')) {
          p = this.itemToPano(t, eii)
        }
        this.getFunc('changePublicData')({
          pano: p,
          tour: t,
          blockCount: this.blockCount(p),
          hotspotCount: this.hotspotCount(p),
          groupGuid: gg,
          groupItems: gis,
          editItemGuid: eig,
          $v: this.$v
        })
      },
      hasGroup(groups) {
        return true
        for (var i in groups) {
          if (groups[i].title) {
            return true
          }
        }
        return false
      },
      itemToPano(tour, index) {
        if (!(tour.items && tour.items.length > 0)) {
          return {}
        }
        if (index >= tour.items.length || index < 0) {
          index = 0
        }
        var item = tour.items[index] || {}
        var pano = item.linkItem
        pano.audio = item.audio
        pano.audioAutoPlay = item.audioAutoPlay
        pano.audioLoop = item.audioLoop
        pano.hotspots = item.hotspots
        pano.title = `${item.title}`//`${tour.title} - ${item.title}`
        pano.text = item.description
        pano.file = item.file
        pano.links = tour.links || []
        if (item.fov && item.fov != 0) {
          pano.fov = item.fov
        }
        pano.hLookAt = item.hLookAt
        pano.vLookAt = item.vLookAt
        if (item.vLookAtMin != 0 || item.vLookAtMax != 0) {
          pano.vLookAtMin = item.vLookAtMin
          pano.vLookAtMax = item.vLookAtMax
        }
        pano.littlePlanetIntro = false
        pano.weather = item.weather
        pano.postprocessing = item.postprocessing || pano.postprocessing
        pano.logo = tour.logo
        pano.hideLogo = tour.hideLogo
        pano.hideCount = tour.hideCount
        pano.paid = tour.paid
        pano.isPro = tour.isPro
        pano.user = tour.user || { userName: '' }
        pano.praiseCount = tour.praiseCount
        pano.viewCount = tour.viewCount
        pano.shareCount = tour.shareCount
        return pano
      },
      hotspotCount(pano) {
        var p = pano || this.form
        return this.getHotspots(p).length
      },
      getHotspots(pano) {
        var p = pano || this.form
        if (!p.hotspots) {
          return []
        }
        return p.hotspots.filter((v) => v.type != 'Block')
      },
      blockCount(pano) {
        var p = pano || this.form
        return this.getBlocks(p).length
      },
      getBlocks(pano) {
        var p = pano || this.form
        if (!p.hotspots) {
          return []
        }
        return p.hotspots.filter((v) => v.type == 'Block')
      },
      linkView(pano) {
        this.getFunc('changePublicData')({ pano: pano })
        this.getFunc('renewSence', 'linkView')
      },
      changePostprocessing(postprocessing) {
        console.log('changePostprocessing', postprocessing)
        this.form.items[this.editItemIndex].postprocessing = postprocessing
        this.pano.postprocessing = postprocessing
        this.getFunc({ target: 'FullPano', name: 'setPostprocessing' })()
      },
      save() {
        //this.updateGroup()
        if (this.updateing) {
          return
        }
        this.$v.$touch()
        if (this.$v.$invalid) {
          for (var j in this.$v.form.links.$each.$iter) {
            if (!this.$v.form.links.$each.$iter[j].url.isUrl) {
              this.$message({
                message: `链接“${this.$v.form.links.$each.$iter[j].$model.title || `${Number(j) + 1}`}”的 Url 不是有效的格式`,
                type: 'warning',
                zIndex: 9999,
                showClose: true,
                onClose: () => {
                  this.$emit('linksInvalid')
                }
              })
              return
            }
          }
          for (var i in this.$v.form.items.$each.$iter) {
            var item = this.$v.form.items.$each.$iter[i]
            console.log(item)
            for (var j in item.hotspots.$each.$iter) {
              var hotspot = item.hotspots.$each.$iter[j]
              if (!hotspot.url.isUrl) {
                this.$message({
                  message: `场景“${item.$model.title || `${Number(i) + 1}`}”热点“${hotspot.$model.title || `${Number(j) + 1}`}”的 Url 不是有效的格式`,
                  type: 'warning',
                  zIndex: 9999,
                  showClose: true,
                  onClose: () => {
                    this.$emit('hotspotsInvalid')
                  }
                })
                return
              }
            }
            //if (item.title.$invalid) {
            //  this.$message({
            //    message: `请检查场景“${item.$model.title || `${Number(i) + 1}`}”的标题`,
            //    type: 'warning',
            //    zIndex: 9999,
            //    showClose: true,
            //    onClose: () => {
            //      this.$emit('titleInvalid')
            //    }
            //  })
            //  return
            //}
          }
          if (this.$v.form.title.$invalid) {
            this.$message({
              message: `请检查标题`,
              type: 'warning',
              zIndex: 9999,
              showClose: true,
              onClose: () => {
                this.$emit('titleInvalid')
              }
            })
            return
          }
          const Msgs = {
            area: '地区',
            mobile: '手机号',
            tel: '固定电话',
            fullName: '姓名',
            street: '地址',
          }
          for (var i in this.$v.form.address) {
            if (this.$v.form.address[i].$invalid) {
              console.log(i, '$invalid')
              this.$message({
                message: `请检查${Msgs[i] || '基础设置'}`,
                type: 'warning',
                zIndex: 9999,
                showClose: true,
                onClose: () => {
                  this.$emit(`address${i}Invalid`)
                }
              })
              return
            }
          }
          for (var i in this.$v.form) {
            if (this.$v.form[i].$invalid) {
              console.log(i, '$invalid')
              this.$message({
                message: `请检查${Msgs[i] || '基础设置'}`,
                type: 'warning',
                zIndex: 9999,
                showClose: true,
                onClose: () => {
                  this.$emit(`${i}Invalid`)
                }
              })
              return
            }
          }
          return
        }
        this.updateing = true;
        this.getFunc('changePublicData')({ updateing: this.updateing })
        this.$axios.put(this.urls.innTourSave({ innId: this.$route.params.innId }), this.form)
          .then((response) => {
            this.$message({
              message: '保存成功',
              type: 'info',
              zIndex: 9999,
            })
            this.close()
            //this.updateing = false;
          })
          .catch((error) => {
            console.log(error)
            this.updateing = false;
            this.getFunc('changePublicData')({ updateing: this.updateing })
          })
      },
      close() {
        this.getFunc({
          target: 'BreakLeave',
          name: 'close'
        })()
      },
    },
  }
</script>
<style scoped>
</style>
